import React, { useState, useEffect } from 'react';
import { Typography, Container, Box, Paper, createTheme, ThemeProvider, Switch, FormControlLabel, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

function InfoPage() {
  const [darkMode, setDarkMode] = useState(() => {
    const saved = localStorage.getItem('darkMode');
    return saved !== null ? JSON.parse(saved) : false;
  });

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#bb86fc' : '#6200ee',
      },
      secondary: {
        main: darkMode ? '#03dac6' : '#03dac6',
      },
      background: {
        default: darkMode ? '#121212' : '#f5f5f5',
        paper: darkMode ? '#1e1e1e' : '#ffffff',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Typography variant="h3" gutterBottom>
            BlockWebAI Guide
          </Typography>
          <FormControlLabel
            control={<Switch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />}
            label={darkMode ? "Dark Mode" : "Light Mode"}
          />
        </Box>

        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Welcome to BlockWebAI
          </Typography>
          <Typography variant="body1" paragraph>
            BlockWebAI is your intelligent companion for a focused web experience. Let's explore how it works and how you can make the most of it.
          </Typography>
        </Paper>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Prompts</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" paragraph>
                  With BlockWebAI, you can set a specific prompt - a topic or task you want to focus on. Our AI evaluates each webpage against this prompt, favoring relevant content and discouraging distractions.
                </Typography>
                <Box sx={{ bgcolor: 'background.default', p: 2, borderRadius: 1 }}>
                  <Typography variant="body2" color="textSecondary">
                    Example: If your prompt is "Study for history test", the AI will prioritize history-related pages and discourage unrelated content.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12} md={6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Whitelists and Blacklists</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" paragraph>
                  Customize your browsing experience with whitelists (always allowed) and blacklists (always blocked) of URLs.
                </Typography>
                <Box sx={{ bgcolor: 'background.default', p: 2, borderRadius: 1 }}>
                  <Typography variant="body2" color="textSecondary">
                    Format: CSV file with one URL per line<br/>
                    Example:<br/>
                    https://www.example.com<br/>
                    https://www.anotherexample.com
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  Note: Adding a URL also includes all its subdomains.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        <Box sx={{ mt: 4, p: 3, bgcolor: 'secondary.main', color: 'secondary.contrastText', borderRadius: 2 }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
            <LightbulbIcon sx={{ mr: 1 }} /> Pro Tip
          </Typography>
          <Typography variant="body1">
            Consider whitelisting educational websites and productivity tools, and blacklisting common distractions to optimize your focus.
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default InfoPage;