import React, { useState, useEffect } from 'react';
import {
  Typography,
  Container,
  Box,
  Paper,
  createTheme,
  ThemeProvider,
  Switch,
  FormControlLabel,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function SetupGuide() {
  const [darkMode, setDarkMode] = useState(() => {
    const saved = localStorage.getItem('darkMode');
    return saved !== null ? JSON.parse(saved) : false;
  });

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#bb86fc' : '#6200ee',
      },
      secondary: {
        main: darkMode ? '#03dac6' : '#03dac6',
      },
      background: {
        default: darkMode ? '#121212' : '#f5f5f5',
        paper: darkMode ? '#1e1e1e' : '#ffffff',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Typography variant="h3" gutterBottom>
            BlockWebAI Setup Guide for Parents
          </Typography>
          <FormControlLabel
            control={<Switch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />}
            label={darkMode ? 'Dark Mode' : 'Light Mode'}
          />
        </Box>

        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Ensuring a Safe Browsing Experience for Your Child
          </Typography>
          <Typography variant="body1" paragraph>
            Follow these comprehensive steps to set up BlockWebAI on your child's device. This guide covers installation and configuration on Windows, macOS, and iOS devices.
          </Typography>
        </Paper>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">General Instructions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              Before proceeding, ensure that you have access to your child's device and the necessary administrative privileges to make changes.
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Install BlockWebAI extension on the browser." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Disable private browsing modes." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Restrict the creation of alternate browser profiles." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Prevent uninstallation of the BlockWebAI extension." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Restrict access to other search engines and browsers." />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Setup on Windows</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle1" gutterBottom>
              Step 1: Install BlockWebAI Extension
            </Typography>
            <Typography variant="body1" paragraph>
              Open the browser (e.g., Chrome, Firefox, Edge) on your child's Windows device and navigate to the BlockWebAI extension page. Click "Add to browser" to install the extension.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 2: Disable Private Browsing
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>For Chrome:</strong> Use Group Policy Editor to disable Incognito mode.
            </Typography>
            <Typography variant="body1" paragraph>
              - Press <kbd>Win + R</kbd>, type <code>gpedit.msc</code>, and press Enter.
            </Typography>
            <Typography variant="body1" paragraph>
              - Navigate to <code>Computer Configuration &gt; Administrative Templates &gt; Google &gt; Google Chrome</code>.
            </Typography>
            <Typography variant="body1" paragraph>
              - Enable the policy "Incognito mode availability" and set it to "Disabled".
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>For Edge:</strong> Similar steps apply using the Edge templates in Group Policy Editor.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 3: Restrict Alternate Profiles
            </Typography>
            <Typography variant="body1" paragraph>
              Use Group Policy to prevent the creation of new profiles in the browser.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 4: Prevent Extension Uninstallation
            </Typography>
            <Typography variant="body1" paragraph>
              - Use Registry Editor to enforce extension installation.
            </Typography>
            <Typography variant="body1" paragraph>
              - Navigate to <code>HKEY_LOCAL_MACHINE\Software\Policies\Google\Chrome\ExtensionInstallForcelist</code>.
            </Typography>
            <Typography variant="body1" paragraph>
              - Add a new string value with the BlockWebAI extension ID.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 5: Restrict Other Browsers and Search Engines
            </Typography>
            <Typography variant="body1" paragraph>
              - Uninstall or restrict access to other browsers.
            </Typography>
            <Typography variant="body1" paragraph>
              - Set parental controls to block installation of new software.
            </Typography>
            <Typography variant="body1" paragraph>
              - Use browser settings to set default search engine and restrict changes.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Setup on macOS</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle1" gutterBottom>
              Step 1: Install BlockWebAI Extension
            </Typography>
            <Typography variant="body1" paragraph>
              Open the browser on your child's Mac and install the BlockWebAI extension from the browser's extension store.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 2: Disable Private Browsing
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>For Safari:</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              - Open Terminal and enter: <code>defaults write com.apple.Safari PrivateBrowsingEnabled -bool false</code>
            </Typography>
            <Typography variant="body1" paragraph>
              - Use parental controls to enforce this setting.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>For Chrome/Firefox:</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              - Use third-party tools like Apple Configurator or parental control software to restrict private browsing modes.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 3: Restrict Alternate Profiles
            </Typography>
            <Typography variant="body1" paragraph>
              - Set up user accounts without admin privileges.
            </Typography>
            <Typography variant="body1" paragraph>
              - Use parental controls to restrict changes to browser settings.

            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 4: Prevent Extension Uninstallation
            </Typography>
            <Typography variant="body1" paragraph>
              - Use Mobile Device Management (MDM) tools to enforce extension installation.
            </Typography>
            <Typography variant="body1" paragraph>
              - Configure profiles to lock extension settings.

            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 5: Restrict Other Browsers and Search Engines
            </Typography>
            <Typography variant="body1" paragraph>
              - Use parental controls to limit access to other browsers.
            </Typography>
            <Typography variant="body1" paragraph>
              - Set default search engines and restrict changes.

            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Setup on iOS</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle1" gutterBottom>
              Step 1: Install BlockWebAI App and Extension
            </Typography>
            <Typography variant="body1" paragraph>
              - Download the BlockWebAI app from the App Store on your child's iOS device.
            </Typography>
            <Typography variant="body1" paragraph>
              - Enable the content blocker extension in Safari settings.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 2: Disable Private Browsing
            </Typography>
            <Typography variant="body1" paragraph>
              - Go to <strong>Settings &gt; Screen Time &gt; Content & Privacy Restrictions</strong>.
            </Typography>
            <Typography variant="body1" paragraph>
              - Enable restrictions and set a passcode.
            </Typography>
            <Typography variant="body1" paragraph>
              - Under <strong>Content Restrictions</strong>, set <strong>Web Content</strong> to "Limit Adult Websites" or "Allowed Websites Only".
            </Typography>
            <Typography variant="body1" paragraph>
              - This disables private browsing in Safari.

            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 3: Restrict Alternate Browsers
            </Typography>
            <Typography variant="body1" paragraph>
              - Under <strong>Allowed Apps</strong>, disable other browsers like Chrome or Firefox.
            </Typography>
            <Typography variant="body1" paragraph>
              - Prevent the installation of new apps by setting <strong>Installing Apps</strong> to "Don't Allow".

            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 4: Prevent Uninstalling Apps
            </Typography>
            <Typography variant="body1" paragraph>
              - In <strong>Content & Privacy Restrictions</strong>, set <strong>Deleting Apps</strong> to "Don't Allow".
            </Typography>
            <Typography variant="body1" paragraph>
              - This prevents your child from uninstalling the BlockWebAI app.

            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 5: Restrict Search Engines
            </Typography>
            <Typography variant="body1" paragraph>
              - In <strong>Content Restrictions</strong>, set <strong>Web Search Content</strong> to limit explicit language.
            </Typography>
            <Typography variant="body1" paragraph>
              - Set the default search engine in Safari settings and restrict changes.

            </Typography>
          </AccordionDetails>
        </Accordion>

        <Box sx={{ mt: 4, p: 3, bgcolor: 'secondary.main', color: 'secondary.contrastText', borderRadius: 2 }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
            <LightbulbIcon sx={{ mr: 1 }} /> Additional Tips
          </Typography>
          <Typography variant="body1">
            Regularly review your child's browsing activity and update the BlockWebAI settings to adapt to their changing needs. Open communication about internet safety is also crucial.
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default SetupGuide;
